<template>

    <div class="fishki">
        <div class="title">
            <p>Стоимость товаров:</p><p class="price">{{ order.producttotal.toLocaleString('ru-RU') }}</p>
        </div>
        <div class="onelbocks" v-if="client._id !== '62fd6ad64886f195911e7395'">
        <div>

            <div class="oneblockProverka promocodeGet">
            <input type="text" placeholder="Код сертификата" v-model="fishki['promocode'].t">

            <svg @click="getPromocodes()" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ><g clip-path="url(#clip0_6821_17755)" ><path d="M24 0H0V24H24V0Z" fill="white" fill-opacity="0.01" ></path> <path d="M21 12H3" stroke="black" stroke-linecap="round" stroke-linejoin="round" ></path> <path d="M15 6L21 12L15 18" stroke="black" stroke-linecap="round" stroke-linejoin="round" ></path></g> <defs ><clipPath id="clip0_6821_17755"><rect width="24" height="24" fill="white" ></rect></clipPath></defs></svg>
           </div>

         </div>
         
         <div class="oneblockProverka">
             <div class="twoblocks">
                  <div  class="customCheckbox" :class="{customCheckboxActivity:prenud5}"
                  @click="prenud5 = !prenud5"></div>
                  
                  <p>Добавить скидку:</p>
             </div>
             <p class="priceFishka">5%</p>

         </div>

         <div class="oneblockProverka" :class="{noactivity:fishkiUser().bonus == 0}" v-if="fishkiUser().bonus !== 0">
             <div class="twoblocks">
                  <div v-if="fishkiUser().bonus !== 0" class="customCheckbox" :class="{customCheckboxActivity:order.bonus !== 0}"
                  @click="order.bonus !== 0 ? changeBonus(0) : changeBonus(fishkiUser().bonus.value)"></div>
                  <div v-else class="customCheckbox"></div>
                  <p>Списать бонусы:</p>
             </div>
             <p class="priceFishka">{{order.bonus !== 0 ? order.bonus : fishkiUser().bonus.value}}</p>

         </div>

         <div class="oneblockProverka" :class="{noactivity:fishkiUser().avans == 0}" v-if="fishkiUser().avans !== 0">
             <div class="twoblocks">
                  <div class="customCheckbox" :class="{customCheckboxActivity:order.avanse !== 0}"

                  @click="order.avanse !== 0 ? changeAvanse(0) : changeAvanse(fishkiUser().avans.value)"
                  v-if="fishkiUser().avans !== 0">
                  </div>
                  <div v-else class="customCheckbox"></div>
                  <p>Списать депозит:</p>
             </div>
             <p class="priceFishka">{{fishkiUser().avans}}</p>

         </div>
         <div class="oneblockProverka" :class="{noactivity:fishkiUser().ad_deposit == 0}" v-if="fishkiUser().ad_deposit !== 0">
             <div class="twoblocks">
                  <div class="customCheckbox" :class="{customCheckboxActivity:order.ad_deposit !== 0}"

                  @click="order.ad_deposit !== 0 ? changeDeposit(0) : changeDeposit(fishkiUser().ad_deposit.value)"
                  v-if="fishkiUser().ad_deposit !== 0">
                  </div>
                  <div v-else class="customCheckbox"></div>
                  <p>Ultimatum Select:</p>
             </div>
             <p class="priceFishka">{{fishkiUser().ad_deposit}}</p>

         </div>
         </div>

         <div class="title itogo">
            <p>Итого:</p><p class="price">{{order.paymentorder.payment.toLocaleString('ru-RU')}}</p>
        </div>

    </div>

</template>

<script setup> 

import { client, updateLogin,prenud5 } from '@/controlles/client'
import { order, fishkiUser, changeBonus, changeAvanse, changeDeposit } from '@/controlles/basket/basket'
import { reactive } from 'vue'
import { notSet } from '@/controlles/general'
import axios from 'axios'

    const fishki = reactive({
    //  bonus: { a: false, p: 0 },
    //  avanse: { a: false, p: 0 },
      promocode: { a: false, p: 0, t: '', l: false, d: 0 }
    })

    const getPromocodes = async () => {
      try {
        await axios.post('https://api.ultimatum.store/certificates/activate', {
          id: client.value._id,
          code: fishki.promocode.t
        })
        await updateLogin(client.value.phone, 'phone')

        notSet('Код активирован')
      } catch (e) {
        notSet('Такого кода нет')
        console.log(e)
      }
    }

</script>

<style scoped>
.fishki{

}
.title{
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    padding: 10px 0px;
}
.title p{

    font-weight: 300;
    color: var(--black);
    font-size: 18px;

}

.customCheckbox{
    border: 1px solid #C4C4C4;
    width: 70px;
    height: 30px;
    border-radius: 50px;
    background: #F3F5F7;
    overflow: hidden;
    transition: 500ms;
    margin: 5px 0px;
}

.customCheckbox:after{
    content: '';
    width: 30px;
    height: 30px;
    display: block;
    background: #A8AEB0;
    border-radius: 100%;
    transition: 500ms;
}

.customCheckboxActivity:after{
    background: var(--black);
    transform: translateX(40px);
}

.oneblockProverka{
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    padding-bottom: 5px;
}
.twoblocks{
    display: grid;
    grid-template-columns: auto auto;
    width: max-content;
    gap: 10px;
    align-items: center;
}
.twoblocks p {
    text-align: center;
    font-weight: 200;
    color: var(--black);
    font-size: 18px;
}

.oneblockProverka .priceFishka {
    text-align: right;
    font-weight: 300;
    color: var(--red);
    font-size: 18px;
}

.twoblocks input{
    padding: 5px;
    width: 177px;
    border: none;
    font-size: 18px;
}

.onelbocks{

    border-bottom: 2px solid var(--new);
}

.itogo{

}

.itogo p {

}

.noactivity{
    opacity: 0.4;
    filter: grayscale(1);
}

.podtext{
    grid-column: span 2;
    font-weight: 200;
    margin: 5px 0px;
    font-size: 16px;
}

.promocodeGet{

    grid-template-columns: 1fr auto;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;

}

.promocodeGet input {
    font-size: 15px;
    margin-bottom: 0;
}

.oknotext p {
    font-size: 18px;
    font-weight: 200;
}

</style>
